// Import Highway
import Highway from '@dogstudio/highway';
import { TweenMax, TimelineMax } from 'gsap';

// Import Transitions & Renderers
import Fade from './transition.js';
import AboutRenderer from './about.js';
import HomeRenderer from './home.js';
import ApproachRenderer from './approach.js';
import DispatchRenderer from './dispatch.js';
import FieldReportRenderer from './fieldreport.js';
import ContactRenderer from './contact.js';

// Call Highway.Core once.
const H = new Highway.Core({
	transitions: {
		default: Fade
	},
	renderers: {
		approach: ApproachRenderer,
		home: HomeRenderer,
		about: AboutRenderer,
		dispatch: DispatchRenderer,
		fieldreport: FieldReportRenderer,
		contact: ContactRenderer
	}
});

// COOKIES NOTIFICATION - GLOBAL

$('.cookies__button').click(function(e) {
	$('.cookies__container').fadeOut('slow', function() {});
});

$(document).ready(function () {

	if (Boolean(readCookie('hide'))) {
		$('.cookies__container').hide();
		$('.cookies__container').fadeOut(1000);
	}
	$('.cookies__button').click(function (e) {

		$('.cookies__wrapper').fadeOut(700);
		$('.cookies__container').removeClass('blur-in');
		$('#cookies__container').addClass('blur-out');
		e.stopPropagation();

		createCookie('hide', true, 1)
		return false;
	});

	function createCookie(name, value, days) {
		if (days == 1) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			var expires = "; expires=" + date.toGMTString();
		}
		else var expires2 = "";
		document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";

	  //  $.cookie(name, value, { expires: days });
	}

	function readCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}

	function eraseCookie(name) {
		createCookie(name, "", -1);
	}

});

// NAVIGATION BUTTON TOGGLE & ANIMATION - GLOBAL

$(document).ready(function() {
	var tl = new TimelineMax({ paused: true });

	tl.to('span.one', 0.4, {
		y: 6,
		rotation: 45,
		ease: Expo.easeInOut,
		delay: -1
	});

	tl.to('span.two', 0.4, {
		y: -4,
		rotation: -45,
		ease: Expo.easeInOut,
		delay: -1
	});

	tl.to('.main__menu-wrapper', 0.6, {
		right: '0%',
		ease: Expo.easeInOut,
		delay: -0.4
	});

	tl.staggerFrom('.main__menu-wrapper ul li', 1, { x: 0, opacity: 0, ease: Expo.easeInOut }, 0.2);
	tl.staggerFrom('.main__menu-social', 1.25, { x: 0, opacity: 0, ease: Expo.easeInOut }, 0, 1.25);

	tl.reverse();
	$(document).on('click', '.main__toggle-btn', function() {
		tl.reversed(!tl.reversed());
	});

	$(document).on('click', '.main__menu-nav ul li a', function() {
		tl.reversed(!tl.reversed());
	});
});

// FADE & BLUR ELEMENTS ON BUTTON CLICK - HOME PAGE
$('.main__toggle-btn').click(function() {
	$('.main__menu-wrapper').css({ display: 'block' });
	$('.main__hero-heading').delay(500).fadeToggle(2000);
	$('.main__hero-cta').delay(500).fadeToggle(2000);
});

// FADE ITEMS BACK ON MENU ITEM CLICK

$('.main__menu-nav ul li.menu__link').click(function() {
	$('.main__menu-wrapper').css({ display: 'block' });
	$('.main__hero-heading').delay(500).fadeToggle(2000);
	$('.main__hero-cta').delay(500).fadeToggle(2000);
});

$('.main__toggle-btn').on('click', function() {
	// HOMEPAGE
	$('.mobile__header-text').toggleClass('blur__filter');
	$('#main__header-content').toggleClass('blur__filter');
	$('.layer-overlay').toggleClass('blur__filter');
	$('.section__map-content-container').toggleClass('blur__filter');
	$('.home__approach-wrap-one').toggleClass('blur__filter');
	$('.main__article-section-wrapper-desktop').toggleClass('blur__filter');
	$('.main__article-section-mobile').toggleClass('blur__filter');
	$('.main__sub-article-section-mobile').toggleClass('blur__filter');
	$('.main__exp-wrapper').toggleClass('blur__filter');
	$('.panel-one').toggleClass('blur__filter');
	$('.panel-two').toggleClass('blur__filter');
	$('.panel-three').toggleClass('blur__filter');
	$('.panel__mobile-1').toggleClass('blur__filter');
	$('.panel__mobile-2').toggleClass('blur__filter');
	$('.panel__mobile-3').toggleClass('blur__filter');
	$('.main__strat-wrapper').toggleClass('blur__filter');
	$('.home__approach-wrap-two').toggleClass('blur__filter');
	$('.section__map-approach-text').toggleClass('blur__filter');
	$('.logo__title').toggleClass('blur__filter');
	$('.logo__container').toggleClass('blur__filter');
	$('.customer-logos').toggleClass('blur__filter');
	$('.prefooter__container').toggleClass('blur__filter');
	$('.una-wrapper').toggleClass('blur__filter');
	// APPROACH PAGE
	$('.approach__header').toggleClass('blur__filter');
	$('.approach__intention').toggleClass('blur__filter');
	$('.approach__strategy').toggleClass('blur__filter');
	$('.approach__tactics').toggleClass('blur__filter');
	$('.approach__action').toggleClass('blur__filter');
	// ABOUT PAGE
	$('.about__title-wrap').toggleClass('blur__filter');
	$('.hero__background').toggleClass('blur__filter');
	$('.about__content-container').toggleClass('blur__filter');
	$('.add__text').toggleClass('blur__filter');
	$('.about__profile-wrapper').toggleClass('blur__filter');
	$('.studio__title-container').toggleClass('blur__filter');
	$('.image__gallery').toggleClass('blur__filter');
	$('.error__wrapper').toggleClass('blur__filter');
	// PRIVACY PAGE
	$('.privacy__title-wrap').toggleClass('blur__filter');
	$('.privacy__content-wrapper').toggleClass('blur__filter');
	// FIELD REPORT PAGE
	$('.post').toggleClass('blur__filter');
	// DISPATCHES
	$('.post-disp').toggleClass('blur__filter');
	$('.cta--a-wrapper').toggleClass('blur__filter');
	// SCROLL LOCK WHEN NAV IS OPEN
	$('body').toggleClass('scroll__lock');
});

$('.main__menu-nav ul li.menu__link').on('click', function() {
	// REMOVE SCROLL LOCK
	$('body').removeClass('scroll__lock');
});


// remove blur on menu item click
$('.main__menu-nav ul li.menu__link').on('click', function() {
		// HOMEPAGE
		$('.mobile__header-text').removeClass('blur__filter');
		$('#main__header-content').removeClass('blur__filter');
		$('.layer-overlay').removeClass('blur__filter');
		$('.section__map-content-container').removeClass('blur__filter');
		$('.home__approach-wrap-one').removeClass('blur__filter');
		$('.main__article-section-wrapper-desktop').removeClass('blur__filter');
		$('.main__article-section-mobile').removeClass('blur__filter');
		$('.main__sub-article-section-mobile').removeClass('blur__filter');
		$('.main__exp-wrapper').removeClass('blur__filter');
		$('.panel-one').removeClass('blur__filter');
		$('.panel-two').removeClass('blur__filter');
		$('.panel-three').removeClass('blur__filter');
		$('.panel__mobile-1').removeClass('blur__filter');
		$('.panel__mobile-2').removeClass('blur__filter');
		$('.panel__mobile-3').removeClass('blur__filter');
		$('.main__strat-wrapper').removeClass('blur__filter');
		$('.home__approach-wrap-two').removeClass('blur__filter');
		$('.section__map-approach-text').removeClass('blur__filter');
		$('.logo__title').removeClass('blur__filter');
		$('.logo__container').removeClass('blur__filter');
		$('.customer-logos').removeClass('blur__filter');
		$('.prefooter__container').removeClass('blur__filter');
		$('.una-wrapper').toggleClass('blur__filter');
		// APPROACH PAGE
		$('.approach__header').removeClass('blur__filter');
		$('.approach__intention').removeClass('blur__filter');
		$('.approach__strategy').removeClass('blur__filter');
		$('.approach__tactics').removeClass('blur__filter');
		$('.approach__action').removeClass('blur__filter');
		// ABOUT PAGE
		$('.about__title-wrap').removeClass('blur__filter');
		$('.hero__background').removeClass('blur__filter');
		$('.about__content-container').removeClass('blur__filter');
		$('.add__text').removeClass('blur__filter');
		$('.about__profile-wrapper').removeClass('blur__filter');
		$('.studio__title-container').removeClass('blur__filter');
		$('.image__gallery').removeClass('blur__filter');
		$('.error__wrapper').removeClass('blur__filter');
		// PRIVACY PAGE
		$('.privacy__title-wrap').removeClass('blur__filter');
		$('.privacy__content-wrapper').removeClass('blur__filter');
});

// RESIZE & SHIFT THE LOGO AND NAV - When the user scrolls down 700px from the top of the document, resize the header - GLOBAL

window.onscroll = function() {
	scrollFunction();
};

function scrollFunction() {
	if (document.body.scrollTop > 700 || document.documentElement.scrollTop > 700) {
		document.querySelector('.main__content-logo svg').style.width = '2.5em';
		document.querySelector('.main__content-logo svg').style.height = '2.5em';
		document.querySelector('.main__content-logo svg path').style.fill = '#3EA89A';
		document.querySelector('.main__toggle-btn span.one').style.background = '#3EA89A';
		document.querySelector('.main__toggle-btn span.two').style.background = '#3EA89A';
		document.querySelector('.main__content-header').style.width = '92%';
	} else {
		document.querySelector('.main__content-logo svg').style.width = '3.75em';
		document.querySelector('.main__content-logo svg').style.height = '3.75em';
		document.querySelector('.main__content-logo svg path').style.fill = '#ffffff';
		document.querySelector('.main__toggle-btn span.one').style.background = '#ffffff';
		document.querySelector('.main__toggle-btn span.two').style.background = '#ffffff';
		document.querySelector('.main__content-header').style.width = '90%';
	}
}

// make adjustments to main content header and find a js media query for screens < 1024 px to manage filled background scroll - remove fade out.

// FADE OUT BUSINESS TAG ON SCROLL - GLOBAL

$(window).scroll(function() {
	$('.business-tag').css('opacity', 1 - $(window).scrollTop() / 200);
});

$(window).scroll(function() {
	if ($(window).width() <= 840) {
		$('.main__content-header').css('opacity', 1 - $(window).scrollTop() / 150);
	} else {
		// Your desktop code goes here
	}
});

//FADE OUT CTA ON SCROLL

$(window).scroll(function() {
	$('.main__hero-cta').css('opacity', 1 - $(window).scrollTop() / 750);
});

// Fade Elements In on Scroll
AOS.init({
	duration: 1200
});

$(document).ready(function() {
	$('.customer-logos').slick({
		centerMode: true,
		centerPadding: '0px',
		infinite: true,
		speed: 1000,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 1000,
		dots: false,
		prevArrow: false,
		nextArrow: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: false
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		]
	});
});

