// File: custom-renderer.js
// Import Highway
import Highway from '@dogstudio/highway';

class HomeRenderer extends Highway.Renderer {
	// Hooks/methods
	onEnterCompleted() {
		window.addEventListener('scroll', function(event) {
			var depth, i, layer, layers, len, movement, topDistance, translate3d;
			topDistance = this.pageYOffset;
			layers = document.querySelectorAll("[data-type='parallax']");
			for (i = 0, len = layers.length; i < len; i++) {
				layer = layers[i];
				depth = layer.getAttribute('data-depth');
				movement = -(topDistance * depth);
				translate3d = 'translate3d(0, ' + movement + 'px, 0)';
				layer.style['-webkit-transform'] = translate3d;
				layer.style['-moz-transform'] = translate3d;
				layer.style['-ms-transform'] = translate3d;
				layer.style['-o-transform'] = translate3d;
				layer.style.transform = translate3d;
			}
		});

		// Home Page Preloader
		window.addEventListener('load', function() {
			var preloader = document.querySelector('.preloader');
			preloader.className += ' hidden'; // class "preloader hidden"
		});

		// Fade Elements In on Scroll
		AOS.init({
			duration: 1200
		});

		// SECTION PANEL LINES - HOME
		$(document).ready(function() {
			$('.panel-test-one').hover(function() {
				$('.border-line').css('height', '100%');
			});
			$('.panel-test-one').mouseleave(function() {
				$('.border-line').css('height', '0');
			});
		});

		$(document).ready(function() {
			$('.panel-test-two').hover(function() {
				$('.border-line').css('height', '100%');
			});
			$('.panel-test-two').mouseleave(function() {
				$('.border-line').css('height', '0');
			});
		});

		$(document).ready(function() {
			$('.panel-test-three').hover(function() {
				$('.border-line').css('height', '100%');
			});
			$('.panel-test-three').mouseleave(function() {
				$('.border-line').css('height', '0');
			});
		});

		//SECTION PANEL HOVER OPACTIY - HOME

		$(document).ready(function() {
			$('.panel-test-one').hover(function() {
				$('.panel-text-two .text-top, .panel-text-three .text-top').css('opacity', '0.2');
				$('.panel-text-two .text-top, .panel-text-three .text-top').css('transition', '0.5s');
				$('.panel-text-two .text-top, .panel-text-three .text-top').css('transition-delay', '0.2s');
				$('.panel-text-one .text-top p').css('opacity', '1');
				$('.panel-text-one .text-top p').css('transition', '0.5s');
				$('.panel-text-one .text-top p').css('transition-delay', '0.2s');
			});
			$('.panel-test-one').mouseleave(function() {
				$('.panel-text-two .text-top, .panel-text-three .text-top').css('opacity', '1');
				$('.panel-text-one .text-top p').css('opacity', '0');
			});
		});

		$(document).ready(function() {
			$('.panel-test-two').hover(function() {
				$('.panel-text-one .text-top, .panel-text-three .text-top').css('opacity', '0.2');
				$('.panel-text-one .text-top, .panel-text-three .text-top').css('transition', '0.5s');
				$('.panel-text-one .text-top, .panel-text-three .text-top').css('transition-delay', '0.2s');
				$('.panel-text-two .text-top p').css('opacity', '1');
				$('.panel-text-two .text-top p').css('transition', '0.5s');
				$('.panel-text-two .text-top p').css('transition-delay', '0.2s');
			});
			$('.panel-test-two').mouseleave(function() {
				$('.panel-text-one .text-top, .panel-text-three .text-top').css('opacity', '1');
				$('.panel-text-two .text-top p').css('opacity', '0');
			});
		});

		$(document).ready(function() {
			$('.panel-test-three').hover(function() {
				$('.panel-text-one .text-top, .panel-text-two .text-top').css('opacity', '0.2');
				$('.panel-text-one .text-top, .panel-text-two .text-top').css('transition', '0.5s');
				$('.panel-text-one .text-top, .panel-text-two .text-top').css('transition', '0.2s');
				$('.panel-text-three .text-top p').css('opacity', '1');
				$('.panel-text-three .text-top p').css('transition', '0.5s');
				$('.panel-text-three .text-top p').css('transition-delay', '0.2s');
			});
			$('.panel-test-three').mouseleave(function() {
				$('.panel-text-one .text-top, .panel-text-two .text-top').css('opacity', '1');
				$('.panel-text-three .text-top p').css('opacity', '0');
			});
		});

		//Article Scroll Arrows
		const buttonRight = document.getElementById('slideRight');
		const buttonLeft = document.getElementById('slideLeft');
	
		buttonRight.onclick = function () {
		  document.getElementById('main__sub-article-wrap').scrollLeft += 310;
		};
		buttonLeft.onclick = function () {
		  document.getElementById('main__sub-article-wrap').scrollLeft -= 310;
		};

	
		





	}
}

// Don`t forget to export your renderer
export default HomeRenderer;
