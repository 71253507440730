import Highway from '@dogstudio/highway';

class AboutRenderer extends Highway.Renderer {
	onEnterCompleted() {
		$(document).ready(function() {
			$('.openBtn.jeff').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-jb')) {
					TweenLite.to('#profile__slider-jb', 1.5, {
						right: '0%',
						ease: Expo.easeInOut,
						opacity: '1'
					});
				}

				return false;
			}); // end click

			$('.closeBtn.jeff').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-jb')) {
					TweenLite.to('#profile__slider-jb', 1.5, {
						right: '-100%',
						ease: Expo.easeInOut,
						opacity: '0'
					});
				}

				return false;
			}); // end click

			$('.openBtn.rachel').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-rg')) {
					TweenLite.to('#profile__slider-rg', 1.5, {
						right: '0',
						ease: Expo.easeInOut,
						opacity: '1'
					});
				}

				return false;
			}); // end click

			$('.closeBtn.rachel').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-rg')) {
					TweenLite.to('#profile__slider-rg', 1.5, {
						right: '-100%',
						ease: Expo.easeInOut,
						opacity: '0'
					});
				}

				return false;
			}); // end click

			$('.openBtn.tanya').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-tz')) {
					TweenLite.to('#profile__slider-tz', 1.5, {
						right: '0',
						ease: Expo.easeInOut,
						opacity: '1'
					});
				}

				return false;
			}); // end click

			$('.closeBtn.tanya').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-tz')) {
					TweenLite.to('#profile__slider-tz', 1.5, {
						right: '-100%',
						ease: Expo.easeInOut,
						opacity: '0'
					});
				}

				return false;
			}); // end click

			$('.openBtn.todd').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-tb')) {
					TweenLite.to('#profile__slider-tb', 1.5, {
						right: '0',
						ease: Expo.easeInOut,
						opacity: '1'
					});
				}

				return false;
			}); // end click

			$('.closeBtn.todd').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-tb')) {
					TweenLite.to('#profile__slider-tb', 1.5, {
						right: '-100%',
						ease: Expo.easeInOut,
						opacity: '0'
					});
				}

				return false;
			}); // end click

			$('.openBtn.james').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-js')) {
					TweenLite.to('#profile__slider-js', 1.5, {
						right: '0',
						ease: Expo.easeInOut,
						opacity: '1'
					});
				}

				return false;
			}); // end click

			$('.closeBtn.james').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-js')) {
					TweenLite.to('#profile__slider-js', 1.5, {
						right: '-100%',
						ease: Expo.easeInOut,
						opacity: '0'
					});
				}

				return false;
			}); // end click

			$('.openBtn.kyla').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-kh')) {
					TweenLite.to('#profile__slider-kh', 1.5, {
						right: '0',
						ease: Expo.easeInOut,
						opacity: '1'
					});
				}

				return false;
			}); // end click

			$('.closeBtn.kyla').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-kh')) {
					TweenLite.to('#profile__slider-kh', 1.5, {
						right: '-100%',
						ease: Expo.easeInOut,
						opacity: '0'
					});
				}

				return false;
			}); // end click

			$('.openBtn.gideon').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-gb')) {
					TweenLite.to('#profile__slider-gb', 1.5, {
						right: '0',
						ease: Expo.easeInOut,
						opacity: '1'
					});
				}

				return false;
			}); // end click

			$('.closeBtn.gideon').click(function() {
				// only runs if #slider is NOT tweening
				if (!TweenMax.isTweening('#profile__slider-gb')) {
					TweenLite.to('#profile__slider-gb', 1.5, {
						right: '-100%',
						ease: Expo.easeInOut,
						opacity: '0'
					});
				}

				return false;
			}); // end click

			console.log('slider.js is running on this page...');

			// button blur on thumbnail click

			$(document).ready(function() {
				$('.openBtn').on('click', function() {
					$('.about__hero').addClass('blur__filter');
					$('.about__content-one').addClass('blur__filter');
					$('.about__profile').addClass('blur__filter');
					$('.about__temp-section').addClass('blur__filter');
					$('.about__studio-header').addClass('blur__filter');
					$('#main__prefooter').addClass('blur__filter');
					$('.main__footer').addClass('blur__filter');
					// scroll lock for slider
					$('body').addClass('scroll__lock');
				});

				$('.closeBtn').on('click', function() {
					$('.about__hero').removeClass('blur__filter');
					$('.about__content-one').removeClass('blur__filter');
					$('.about__profile').removeClass('blur__filter');
					$('.about__temp-section').removeClass('blur__filter');
					$('.about__studio-header').removeClass('blur__filter');
					$('#main__prefooter').removeClass('blur__filter');
					$('.main__footer').removeClass('blur__filter');
					//remove scroll lock for slider
					$('body').removeClass('scroll__lock');
				});
			});

			// image hover

			$('.about__team-hs').on('mouseover', function(event) {
				$('.about__team-hs').not($(this)).addClass('hover');
			});

			$('.about__team-hs').on('mouseout', function() {
				$('.about__team-hs').not($(this)).removeClass('hover');
			});

			// read more/less
			$('span.moreless-button').click(function() {
				$('span.moretext').slideToggle();
				if ($('span.moreless-button').text() == 'Read More +') {
					$(this).text('Read Less -');
				} else {
					$(this).text('Read More +');
				}
			});

			$('span.moreless-studio').click(function() {
				$('span.moretext-studio').slideToggle();
				if ($('span.moreless-studio').text() == 'Read More') {
					$(this).text('Read Less');
				} else {
					$(this).text('Read More');
				}
			});
		});

		// Home Page Preloader
		window.addEventListener('load', function() {
			var preloader = document.querySelector('.preloader');
			preloader.className += ' hidden'; // class "preloader hidden"
		});

		// Fade Elements In on Scroll
		AOS.init({
			duration: 1200
		});
	}
}

export default AboutRenderer;
