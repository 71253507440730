import Highway from '@dogstudio/highway';

class ContactRenderer extends Highway.Renderer {
    onEnter() {
            // mail.php

            $(document).ready(function() {
                //Email Validation Function 
                function isValidEmailAddress(emailAddress) {
                    var pattern =/((\w|\-))+\@((\w|\-))+\.((\w|\-))+/;
                    return pattern.test(emailAddress);
                };

                var delay = 200;
                $('#submit').click(function(e){
                e.preventDefault();

                var fname = $('#fname').val();
                if(fname == ''){
                $('#status').html(
                '<span style="color:red;">Enter Your First Name</span>'
                );
                $('#fname').focus();
                return false;
                }

                var lname = $('#lname').val();
                if(lname == ''){
                $('#status').html(
                '<span style="color:red;">Enter Your Last Name</span>'
                );
                $('#lname').focus();
                return false;
                }

                var email = $('#email').val();
                if(email == ''){
                $('#status').html(
                '<span style="color:red;">Enter Email Address</span>'
                );
                $('#email').focus();
                return false;
                }
                if( $("#email").val()!='' ){
                if( !isValidEmailAddress( $("#email").val() ) ){
                $('#status').html(
                '<span style="color:red;">Provided user email address is invalid</span>'
                );
                $('#email').focus();
                return false;
                }
                }

                var phone = $('#phone').val();
                if(phone == ''){
                $('#status').html(
                '<span style="color:red;">Enter Your Phone Number</span>'
                );
                $('#phone').focus();
                return false;
                }

                var cname = $('#cname').val();
                if(cname == ''){
                $('#status').html(
                '<span style="color:red;">Enter Your Company/Project Name</span>'
                );
                $('#cname').focus();
                return false;
                }

                var message = $('#message').val();
                if(message == ''){
                $('#status').html(
                '<span style="color:red;">Enter Your Message Here</span>'
                );
                $('#message').focus();
                return false;
                } 

                $.ajax
                ({
                type: "POST",
                url: "mail.php",
                data: {
                    fname: fname,
                    lname: lname,
                    email: email,
                    phone: phone,
                    cname: cname,
                    message: message,
                },
                beforeSend: function() {
                $('#status').html(
                '<h3>Sending...</h3>'
                );
                }, 
                success: function(data)
                {
                setTimeout(function() {
                $('#status').html(data);
                }, delay);
                }
                });
                });
            });

    }
}

export default ContactRenderer;