import Highway from '@dogstudio/highway';

class ApproachRenderer extends Highway.Renderer {
	onEnterCompleted() {
		// Home Page Preloader
		window.addEventListener('load', function() {
			var preloader = document.querySelector('.preloader');
			preloader.className += ' hidden'; // class "preloader hidden"
		});

		// Fade Elements In on Scroll
		AOS.init({
			duration: 1200
		});
		
		//read buttons
		$('.read-button-title').click(function() {
			$('.read-title').slideToggle();
			if ($('.read-button-title').text() == 'Read More +') {
				$(this).text('Read Less -');
			} else {
				$(this).text('Read More +');
			}
		});

		$('.read-button-intention').click(function() {
			$('.read-intention').slideToggle();
			if ($('.read-button-intention').text() == 'Read More +') {
				$(this).text('Read Less -');
			} else {
				$(this).text('Read More +');
			}
		});

		$('.read-button-intention-mobile').click(function() {
			$('.read-intention-mobile').slideToggle();
			if ($('.read-button-intention-mobile').text() == 'Read More +') {
				$(this).text('Read Less -');
			} else {
				$(this).text('Read More +');
			}
		});

		$('.read-button-strategy').click(function() {
			$('.read-strategy').slideToggle();
			if ($('.read-button-strategy').text() == 'Read More +') {
				$(this).text('Read Less -');
			} else {
				$(this).text('Read More +');
			}
		});

		$('.read-button-strategy-mobile').click(function() {
			$('.read-strategy-mobile').slideToggle();
			if ($('.read-button-strategy-mobile').text() == 'Read More +') {
				$(this).text('Read Less -');
			} else {
				$(this).text('Read More +');
			}
		});

		$('.read-button-tactics').click(function() {
			$('.read-tactics').slideToggle();
			if ($('.read-button-tactics').text() == 'Read More +') {
				$(this).text('Read Less -');
			} else {
				$(this).text('Read More +');
			}
		});

		$('.read-button-tactics-mobile').click(function() {
			$('.read-tactics-mobile').slideToggle();
			if ($('.read-button-tactics-mobile').text() == 'Read More +') {
				$(this).text('Read Less -');
			} else {
				$(this).text('Read More +');
			}
		});

		$('.read-button-action').click(function() {
			$('.read-action').slideToggle();
			if ($('.read-button-action').text() == 'Read More +') {
				$(this).text('Read Less -');
			} else {
				$(this).text('Read More +');
			}
		});

		$('.read-button-action-mobile').click(function() {
			$('.read-action').slideToggle();
			if ($('.read-button-action-mobile').text() == 'Read More +') {
				$(this).text('Read Less -');
			} else {
				$(this).text('Read More +');
			}
		});
	}
}

export default ApproachRenderer;
