import Highway from '@dogstudio/highway';

class FieldReportRenderer extends Highway.Renderer {
    onEnter() {
        $(document).ready(function(){
            //Pop Up
            function PopupCenterDual(url, title, w, h) {
                // Fixes dual-screen position Most browsers Firefox
                var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
                var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
                width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
            
                var left = ((width / 2) - (w / 2)) + dualScreenLeft;
                var top = ((height / 2) - (h / 2)) + dualScreenTop;
                var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
            
                // Puts focus on the newWindow
                if (window.focus) {
                newWindow.focus();
              }
            }
            // SHOW/HIDE EMAIL SHARE
            $("#shareButton").click(function() {
                $("#emailShare").toggle();
              });

            $(".EmailShareForm-menuCloseButtonContainer").click(function(){
              $("#emailShare").hide();
            });  

            // cta_mail.php
            $(document).ready(function() {
                //Email Validation Function 
                function isValidEmailAddress(emailAddress) {
                    var pattern =/((\w|\-))+\@((\w|\-))+\.((\w|\-))+/;
                    return pattern.test(emailAddress);
                };
    
                var delay = 200;
                $('#submit').click(function(e){
                e.preventDefault();
    
                var friend_email = $('#friend_email').val();
                if(friend_email == ''){
                $('#status').html(
                '<span style="color:red;">Enter Email Address!</span>'
                );
                $('#friend_email').focus();
                return false;
                }
                if( $("#friend_email").val()!='' ){
                if( !isValidEmailAddress( $("#friend_email").val() ) ){
                $('#status').html(
                '<span style="color:red;">Provided friend email address is incorrect!</span>'
                );
                $('#friend_email').focus();
                return false;
                }
                }
    
                var your_email = $('#your_email').val();
                if(your_email == ''){
                $('#status').html(
                '<span style="color:red;">Enter Email Address!</span>'
                );
                $('#your_email').focus();
                return false;
                }
                if( $("#your_email").val()!='' ){
                if( !isValidEmailAddress( $("#your_email").val() ) ){
                $('#status').html(
                '<span style="color:red;">Provided user email address is incorrect!</span>'
                );
                $('#your_email').focus();
                return false;
                }
                }
    
                var message = $('#message').val();
                if(message == ''){
                $('#status').html(
                '<span style="color:red;">Enter Your Message Here!</span>'
                );
                $('#message').focus();
                return false;
                } 
    
                $.ajax
                ({
                type: "POST",
                url: "cta_mail.php",
                data: {
                    your_email: your_email,
                    friend_email: friend_email,
                    message: message,
                },
                beforeSend: function() {
                $('#status').html(
                '<h3>Sending...</h3>'
                );
                }, 
                success: function(data)
                {
                setTimeout(function() {
                $('#status').html(data);
                }, delay);
                }
                });
                });
            
            });

            $(function(){
                var random = Math.floor(Math.random() * $('.list_random').length);
                $('.list_random').eq(random).show();
              })
        })
    }
}

export default FieldReportRenderer;